import { Link } from "gatsby";
import React from "react";
import VideoComponent from "../VideoComponent";

const RowsSection = ({
  isColReverse,
  subHeading,
  heading,
  description,
  btnText,
  btnLink,
  imgSrc,
  videoSrc,
  imgAlt,
  isReverse,
  imageWidth,
  imageHeight,
  imageContainer,
  isDicomNiftiPage = false,
}) => {
  return (
    <section
      className={`mx-auto flex ${
        isReverse ? "lg:flex-row-reverse" : "lg:flex-row"
      } ${
        isColReverse ? "flex-col-reverse" : "flex-col"
      }  py-6 justify-between bg-inherit mb-6 lg:gap-16`}
    >
      <div className="flex flex-col self-start flex-1 max-w-lg p-5 mb-5 space-y-3 text-left lg:self-center sm:space-y-5 lg:mb-0 md:mb-20 2xl:p-10 md:p-8">
        <h3 className="text-sm font-medium uppercase text-gray-1200">
          {subHeading}
        </h3>

        <h4
          className={`text-purple-1000 text-xl sm:text-2.5xl font-semibold ${
            isDicomNiftiPage ? "lg:!pr-0" : "lg:pr-10"
          }`}
        >
          {heading}
        </h4>

        <p
          className={`max-w-md text-base text-gray-600 ${
            isDicomNiftiPage ? "lg:!text-xl" : "lg:text-base"
          }`}
        >
          {description}
        </p>

        {btnLink && (
          <Link
            to={btnLink}
            className="flex items-center font-bold text-purple-500 w-max"
          >
            <span>{btnText}</span>
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0259 8.28787L4.53439 13.3581C4.51743 13.3715 4.49705 13.3798 4.47558 13.382C4.45411 13.3843 4.43243 13.3805 4.41304 13.371C4.39364 13.3616 4.3773 13.3468 4.36591 13.3285C4.35452 13.3101 4.34853 13.289 4.34863 13.2674V12.1543C4.34863 12.0837 4.38175 12.016 4.43647 11.9728L9.62045 7.92499L4.43647 3.87715C4.38031 3.83395 4.34863 3.76627 4.34863 3.69571V2.5826C4.34863 2.48612 4.45951 2.43284 4.53439 2.49188L11.0259 7.56211C11.0811 7.60515 11.1257 7.6602 11.1564 7.72308C11.1871 7.78596 11.203 7.85501 11.203 7.92499C11.203 7.99496 11.1871 8.06401 11.1564 8.1269C11.1257 8.18978 11.0811 8.24483 11.0259 8.28787Z"
                fill="#5658DD"
              />
            </svg>
          </Link>
        )}
      </div>

      <div className={`rounded-3xl flex-1 flex ${imageContainer}`}>
        {videoSrc ? (
          <VideoComponent loop autoPlay muted>
            <source
              src={videoSrc}
              type="video/mp4"
              className="w-full h-auto p-5 mx-auto rounded-2xl 2xl:p-10 md:p-8"
            />
          </VideoComponent>
        ) : (
          <img
            src={imgSrc}
            width={imageWidth || 0}
            height={imageHeight || 0}
            alt={imgAlt || "img"}
            loading="lazy"
            className="w-full h-auto max-h-full p-5 mx-auto my-auto rounded-2xl 2xl:p-10 md:p-8"
          />
        )}
      </div>
    </section>
  );
};

export default RowsSection;
