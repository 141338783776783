import React from "react";

const CenterCardSection = ({
  heading,
  description,
  imgSrc,
  imgAlt,
  videoSrc,
  isReverse,
  imageWidth,
  imageHeight,
}) => {
  return (
    <div
      className={`lg:max-w-sm max-w-2xl mx-auto flex ${
        isReverse ? "md:flex-row-reverse" : "md:flex-row"
      } lg:flex-col flex-col overflow-hidden rounded-2xl shadow-lg bg-white md:mb-6 mb-4 lg:h-[400px] w-full`}
    >
      <section className="flex-1 h-64 lg:flex-none">
        {videoSrc ? (
          <VideoComponent loop autoPlay muted>
            <source
              src={videoSrc}
              type="video/mp4"
              className="w-full h-72 bg-gray-1800"
            />
          </VideoComponent>
        ) : (
          <img
            src={imgSrc}
            alt={imgAlt || "img"}
            className="w-full md:h-full bg-gray-1800"
            loading="lazy"
            width={imageWidth || 0}
            height={imageHeight || 0}
          />
        )}
      </section>

      <div className="flex flex-col justify-center flex-1 p-6 space-y-3 text-left sm:space-y-5 lg:flex-none">
        <h4 className="text-2xl font-semibold text-purple-1000">{heading}</h4>

        <p className="max-w-md text-base text-gray-600 line-clamp-2">
          {description}
        </p>
      </div>
    </div>
  );
};

export default CenterCardSection;
