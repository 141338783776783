import React from "react";
import NewHomeLeadFormWithAPIForm from "../NewHomeLeadFormWithAPI";
import VideoComponent from "../VideoComponent";
import LogoMarquee from "../LogoMarquee";
import { Link } from "gatsby-link";

const buttonStyles = {
  Github: "bg-[#4C4C50]",
  "Join our Discord": "!bg-white !text-purple-500 flex-row-reverse",
};

const HeroSection = ({
  videoPoster,
  logoSlider,
  location,
  heading,
  description,
  videoSRC,
  imageSRC,
  pageName,
  buttonList,
  showForm = true,
  marginTop = "mt-8",
  videoMarginTop = "",
  trusted_brands_list,
  containerClasses,
  imageWidth,
  imageHeight,
  descriptionMargin = "md:mb-9 mb-5",
  videoClass,
  showGithubAndDocsBtn,
  isActivePage,
}) => {
  return (
    <>
      <section className={`max-w-4xl w-full mx-auto ${containerClasses}`}>
        <h1
          dangerouslySetInnerHTML={{ __html: heading }}
          className="h1_gradient text-2xl sm:leading-tight sm:text-4.5xl font-semibold mb-5"
        />

        <p
          className={`max-w-2xl mx-auto text-sm text-gray-600 sm:text-xl ${descriptionMargin}`}
        >
          {description}
        </p>

        {showForm && (
          <section className="flex justify-center">
            <NewHomeLeadFormWithAPIForm
              pageName={pageName}
              location={location}
              btnText="Book a demo"
            />
          </section>
        )}
        {showGithubAndDocsBtn && (
          <section className="flex justify-center">
            <ul className="flex flex-wrap items-center justify-center flex-grow">
              <li className="mr-3 sm:mr-5">
                <Link
                  to="https://github.com/encord-team/encord-active/"
                  target="_blank"
                  className={
                    "flex shadow-mditems-center text-sm sm:text-base font-manrope bg-purple-1500 hover:bg-[#5E24FF] text-white font-semibold  w-ful sm:w-auto px-7 py-1.5 sm:py-2 lg:px-4 rounded-full items-center"
                  }
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                  </svg>
                  Github Repo
                </Link>
              </li>

              <li>
                <Link
                  to="https://docs.encord.com/docs/active-overview/"
                  target="_blank"
                  className={
                    "text-sm sm:text-base font-manrope font-semibold bg-gray-1600 hover:bg-gray-2900 text-gray-2500 sm:w-auto px-7 sm:py-3.5 py-3 lg:px-7 rounded-full shadow-md"
                  }
                >
                  Docs
                </Link>
              </li>
            </ul>
          </section>
        )}
        {buttonList && (
          <section className="flex flex-wrap justify-center gap-3 items-cente sm:flex-nowrap">
            {buttonList.map((button, index) => {
              const { button_icon, button_text, button_link } = button?.primary;
              return (
                <a
                  href={button_link?.url || ""}
                  key={index}
                  rel="noopener noreferrer"
                  target="__blank"
                  className={`flex justify-center flex-shrink-0  shadow-card gap-2 bg-purple-500 py-3 px-10 rounded-full text-white text-sm 2xl:text-base font-semibold ${
                    buttonStyles[button_text.text]
                  }`}
                >
                  {button_icon?.url && (
                    <img
                      src={button_icon?.url}
                      alt={button_text?.text || "btn-text"}
                      width={0}
                      height={0}
                      className="w-5 h-auto"
                    />
                  )}
                  {button_text.text}
                </a>
              );
            })}
          </section>
        )}
        {isActivePage && (
          <div className="w-full mt-9">
            <VideoComponent
              poster={videoPoster}
              loop
              autoPlay
              muted
              className="w-full h-auto mx-auto rounded-xl shadow-card"
            >
              <source src={videoSRC} type="video/mp4" />
            </VideoComponent>
          </div>
        )}
      </section>

      {!logoSlider && (
        <section className="mx-auto space-y-4 section_container py-9">
          <section className="img_grayscale_opacity">
            <p className="text-center uppercase text-2xs text-gray-1300 tracking-[0.07em]">
              {trusted_brands_list?.primary?.section_heading?.text}
            </p>
          </section>

          <section
            className={`grid grid-cols-4 items-center justify-center ${
              trusted_brands_list?.items?.length === 9
                ? "md:grid-cols-9  max-w-[50rem] gap-1 mx-auto sm:gap-4"
                : trusted_brands_list?.items?.length == 8
                ? "md:grid-cols-8 max-w-3xl gap-1 mx-auto sm:gap-4 lg:gap-8"
                : "md:grid-cols-7 max-w-4xl gap-1 mx-auto sm:gap-4"
            } `}
          >
            {trusted_brands_list?.items?.map((item, index) => {
              return (
                <div
                  key={`brand_${index}`}
                  className={`flex 
                ${
                  item?.brand_logo?.alt === "uhn"
                    ? "justify-start"
                    : "justify-center"
                }
                col-span-1 img_grayscale_opacity
                ${
                  item?.brand_logo?.dimensions?.width == 200 &&
                  item?.brand_logo?.dimensions?.height == 200
                    ? "h-18 ml-3"
                    : item?.brand_logo?.dimensions?.width == 300 &&
                      item?.brand_logo?.dimensions?.height == 75
                    ? "sm:w-22"
                    : item?.brand_logo?.dimensions?.width == 1165 &&
                      "lg:h-32 lg:w-28 lg:-ml-2"
                }`}
                >
                  <img
                    src={item?.brand_logo?.url}
                    alt={item?.brand_logo?.alt || "img"}
                    className={`${"object-scale-down h-auto w-auto"} img_grayscale`}
                    loading="lazy"
                    width={item?.brand_logo?.dimensions?.width || 0}
                    height={item?.brand_logo?.dimensions?.height || 0}
                  />
                </div>
              );
            })}
          </section>
        </section>
      )}

      {!isActivePage && (
        <>
          {videoSRC ? (
            <div
              className={`${
                videoPoster && "max-w-5xl mx-auto"
              } max-h-[600px] min-h-[510px] bg-gray-200 max-w-[1000px] mx-auto rounded-lg shadow-card`}
            >
              <VideoComponent
                poster={videoPoster}
                loop
                autoPlay
                muted
                className={`mx-auto w-full h-auto ${videoMarginTop} ${videoClass}`}
              >
                <source src={videoSRC} type="video/mp4" />
              </VideoComponent>
            </div>
          ) : (
            <>
              {imageSRC ? (
                <img
                  src={imageSRC}
                  loading="eager"
                  width={imageWidth || 0}
                  height={imageHeight || 0}
                  alt="stats"
                  className={`${marginTop} max-w-4xl mx-auto w-full h-auto"`}
                />
              ) : (
                <section className="w-full mt-20 bg-gray-200 2xl:mt-28 h-128" />
              )}
            </>
          )}
        </>
      )}

      {logoSlider && (
        <section className="mx-auto space-y-4 section_container py-9">
          <section className="img_grayscale_opacity">
            <p className="text-center uppercase text-2xs text-gray-1300 tracking-[0.07em]">
              {trusted_brands_list?.primary?.label?.text}
            </p>
          </section>
          <LogoMarquee logoArray={trusted_brands_list} />
        </section>
      )}
    </>
  );
};

export default HeroSection;
